<template>
  <div class="home">
    <div class="init-error">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="768" height="768" viewBox="0 0 768 768">
        <path d="M736 384c0-97.184-39.424-185.248-103.104-248.896s-151.712-103.104-248.896-103.104-185.248 39.424-248.896 103.104-103.104 151.712-103.104 248.896 39.424 185.248 103.104 248.896 151.712 103.104 248.896 103.104 185.248-39.424 248.896-103.104 103.104-151.712 103.104-248.896zM672 384c0 79.552-32.192 151.488-84.352 203.648s-124.096 84.352-203.648 84.352-151.488-32.192-203.648-84.352-84.352-124.096-84.352-203.648 32.192-151.488 84.352-203.648 124.096-84.352 203.648-84.352 151.488 32.192 203.648 84.352 84.352 124.096 84.352 203.648zM537.6 492.8c0 0-4.192-5.536-10.592-12.256-4.64-4.896-10.816-10.912-18.464-17.28-10.784-8.992-24.704-18.88-41.504-27.296-22.56-11.264-50.528-19.968-83.040-19.968s-60.48 8.704-83.040 19.968c-16.8 8.416-30.72 18.304-41.504 27.296-7.648 6.368-13.824 12.384-18.464 17.28-6.4 6.72-10.592 12.256-10.592 12.256-10.592 14.144-7.744 34.208 6.4 44.8s34.208 7.744 44.8-6.4c1.312-1.6 5.792-6.592 5.792-6.592 3.2-3.36 7.584-7.648 13.056-12.224 7.776-6.496 17.568-13.408 29.12-19.2 15.36-7.648 33.6-13.184 54.432-13.184s39.072 5.536 54.432 13.216c11.52 5.76 21.344 12.704 29.12 19.2 5.472 4.576 9.856 8.864 13.056 12.224 4.48 4.96 5.792 6.56 5.792 6.56 10.592 14.144 30.656 16.992 44.8 6.4s16.992-30.656 6.4-44.8zM288 320c17.664 0 32-14.336 32-32s-14.336-32-32-32-32 14.336-32 32 14.336 32 32 32zM480 320c17.664 0 32-14.336 32-32s-14.336-32-32-32-32 14.336-32 32 14.336 32 32 32z"></path>
      </svg>
      this page doesn't exist
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  metaInfo: {
    title: 'Page not found - EenyMeenyMotionPictures',
    titleTemplate: '%s',
  },
}
</script>

<style lang="scss" scoped>
  .home {
    height: 100vh;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /deep/ .text {
    margin-top: -3rem;
  }
</style>
